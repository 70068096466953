<template>
  <div>
    <div class="flex mb-6">
      <router-link
        class="mr-6"
        to="contract"
        v-if="this.$store.getters.userIsRoot"
      >
        <r-button-action
          icon="add"
          title="Добавить договор"
        />
      </router-link>
      <!-- <p class="flex align-items-center sulguni color-rocky mr-6">
        <r-icon fill="rocky" size="16"
          class="mr-2"
          icon="add"
        />
        Загрузить данные
      </p>-->
      <r-button-action
        @click="csvExport"
        :disabled="!filteredObjects.length"
        icon="load"
        title="Скачать в CSV"
      />
    </div>
    <div class="flex mb-6">
      <r-input
        class="flex-1 mr-6"
        label="Кадастровый номер"
        v-model.trim="cadastralNum"
        v-mask="'##:##XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX'"
      />
      <r-select
        class="flex-1 mr-6"
        :items="sortTypes"
        label="Статус"
        v-model="selectedSortType"
        @input="changeFilter($event, 'selectedSortType')"
      />
      <r-select
        class="flex-1 mr-6"
        :items="[
          {
            id: 'ALL',
            title: 'Все',
          },
          ...$store.state.contracts.typeOfLaw,
        ]"
        label="Вид права"
        v-model="selectedSortTypeLaw"
        @input="changeFilter($event, 'selectedSortTypeLaw')"
      />

      <r-input
        class="flex-1 mr-6"
        label="Поиск"
        v-model="search"
        before-icon="search"
      />

      <r-button-simple
        size="larishae"
        icon="menu"
        icon-size="20"
        @click="selectedView = 'table'"
        :type="selectedView === 'map' ? 'secondary' : 'primary'"
      />
      <r-button-simple
        size="larishae"
        class="ml-2"
        icon="geopoint"
        :type="selectedView === 'map' ? 'primary' : 'secondary'"
        icon-size="20"
        @click="selectedView = 'map'"
      />
    </div>
    <loader v-if="isLoading" />
    <template v-else>
      <div class="flex mb-6 align-items-center">
        <r-checkbox
          v-model="isYear"
          label="Год окончания договора"
        />
        <drop-down-check-list
          class="ml-4"
          :checklist="['2021', '2020', '2019']"
          :disabled="!isYear"
          v-model="years"
        />
        <div class="ml-auto parmigiano color-titanic opacity-48">
          {{ !!searchText ? 'Найдено' : 'Всего' }} {{ countObjects }}
        </div>
      </div>

      <contracts-table
        :list="filteredObjects"
        v-if="selectedView === 'table'"
      />
      <div
        v-if="selectedView === 'map'"
        class="objects__map"
        ref="map"
      >
        <polygon-map
          collapse-btn
          style="height: 648px"
          class="mb-4"
          ruler
          :balloon-component="contractBalloon"
          :key="mapkey"
        >
          <div
            v-for="(pol, index) in contractsForMap"
            :key="index + 'contracts'"
          >
            <ymap-marker
              :marker-id="index + 'pol2'"
              marker-type="polygon"
              :coords="pol.coordinates"
              :marker-fill="{ color: '#3d75e4', opacity: 0.16 }"
              :marker-stroke="{ color: '#3d75e4', width: 2 }"
              fill-rule="nonZero"
              :balloon-component-props="{
                item: pol,
                openArchiveModal: openArchiveModal,
              }"
            />
          </div>
        </polygon-map>
      </div>
    </template>
  </div>
</template>

<script>
import _ from 'lodash';
import PolygonMap from './PolygonMap';
import DropDownCheckList from './DropDownCheckList';
import ContractsTable from './ContractsTable';
import { getNumberOfDays, wordMatch } from '../helpers/utils';
import DeleteModal from './DeleteModal';
import Api from '../api/Api';
import ContractBalloon from './ContractBalloon';
import Loader from './Loader';

export default {
  name: 'TabContracts',
  components: {
    Loader,
    DropDownCheckList,
    ContractsTable,
    PolygonMap
  },
  data() {
    return {
      selectedView: 'table',
      selectedSortType: 1,
      searchText: '',
      cadastralNum: '',
      isYear: false,
      list: [],
      sortTypes: [
        {
          id: 1,
          title: 'Активные'
        },
        {
          id: 0,
          title: 'Архивные'
        },
        {
          id: 2,
          title: 'Заканчиваются через месяц'
        }
      ],
      isShowMap: false,
      years: [],
      contractsForMap: [],
      selectedSortTypeLaw: 'ALL',
      mapkey: 0
    };
  },
  computed: {
    contractBalloon() {
      return ContractBalloon;
    },
    isLoading() {
      return this.$store.state.contracts.contractsLoading;
    },
    filteredObjects() {
      let list = this.$store.getters.getContractsForTable;
      // Если заполнена строка поиска
      if (this.searchText) {
        list = list.filter(
          el => wordMatch(el?.columns?.title || '', this.searchText) <= 0.5
            || wordMatch(el?.columns?.person || '', this.searchText) <= 0.5
            || wordMatch(el?.columns?.contractNum || '', this.searchText) <= 0.5
            || wordMatch(el?.columns?.code || '', this.searchText) <= 0.5
        );
      }
      if (this.cadastralNum) {
        list = list.filter(
          el => wordMatch(el?.columns?.cadastralNum?.title || '', this.cadastralNum) <= 0.8
        );
      }
      // if (this.isYear) {
      //
      // }
      if (this.selectedSortTypeLaw !== 'ALL') {
        list = list.filter(
          el => el.typeOfLawKey === this.selectedSortTypeLaw
        );
      }
      if (this.selectedSortType !== 2) {
        list = list.filter(
          el => el.active === !!this.selectedSortType
        );
      } else {
        list = list.filter(el => el.endThisMonth);
      }
      return list;
    },
    countObjects() {
      return this.filteredObjects?.length || 0;
    },
    search: {
      get() {
        return this.searchText;
      },
      set(val) {
        if (this.timeoutId) clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(() => {
          this.searchText = typeof val === 'string' ? val.toLowerCase() : null;
        }, 420);
      }
    },
    inputMask: {
      get() {
        return this.cadastralNum;
      },
      set(val) {
        if (val) {
          const mask = val
            .replace(/\D/g, '')
            .replace(/(\d{2})(\d)/, '$1:$2')
            .replace(/(\d{2})(\d)/, '$1:$2');
          this.cadastralNum = mask.length ? mask : null;
        }
      }
    }
  },
  activated() {
    this.mapkey++;
  },
  async mounted() {
    const setFiltersField = [
      'selectedSortType',
      'selectedSortTypeLaw'
    ];
    setFiltersField.forEach(field => {
      const initialField = this[field];
      this[field] = localStorage.getItem(field) || initialField;
    });
    await this.$store.dispatch('getContracts');

    const contractsForMap = this.filteredObjects.reduce((acc, cur) => {
      acc[cur.mapObject.id] = acc[cur.mapObject.id] || {
        id: cur.mapObject.id,
        slides: [],
        coordinates: []
      };
      acc[cur.mapObject.id].slides.push(cur);
      if (acc[cur.mapObject.id].coordinates.length < 1) {
        acc[cur.mapObject.id].coordinates = cur.mapObject.wgs.coordinates.map(polygon => polygon.map(coordinate => [coordinate[1], coordinate[0]]));
      }
      return acc;
    }, {});

    this.contractsForMap = Object.values(contractsForMap);
  },
  methods: {
    changeFilter(e, field) {
      localStorage.setItem(field, e);
    },
    csvExport() {
      const arrData = this.filteredObjects;
      if (!arrData.length) return;
      let csvContent = 'data:text/csv;charset=utf-8,\uFEFF';
      const head = this.$store.state.contracts.headings.map(el => el.title);
      csvContent += [
        head.join(';'),
        ...arrData.map(item => {
          const cols = [];
          for (const key in item.columns) {
            item.columns[key]?.title ? cols.push(item.columns[key]?.title) : cols.push(item.columns[key]);
          }
          return cols.join(';');
        })
      ]
        .join('\n')
        .replace(/(^\[)|(\]$)/gm, '');
      const data = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', data);
      link.setAttribute('download', 'Договоры.csv');
      link.click();
    },
    openArchiveModal(id) {
      this.$rir.modal.open(DeleteModal, {
        icon: false,
        title: 'Архивировать договор?',
        subtitle: 'Договор останется в базе данных и будет перенесён в «Архивные»',
        buttons: ['Не архивировать', 'Архивировать'],
        action: () => { this.archive(id); }
      });
    },
    archive(id) {
      new Api().deleteContract(id).then(() => {
        const r = this.$router.resolve({
          name: 'index'
        });
        window.location.assign(r.href);
      });
    }
  }
};
</script>

<style scoped>
::v-deep .ymaps-2-1-79-balloon__layout,
::v-deep .ymaps-2-1-79-balloon__content{
  background: transparent!important;
}
::v-deep .ymaps-2-1-79-balloon{
  box-shadow: none!important;
}
</style>
